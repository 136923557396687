import React from "react"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{
      fontSize: '12em',
      width: '100%',
      textAlign: 'center',
    }}>404</h1>
  </Layout>
)

export default NotFoundPage
